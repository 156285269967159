.slides{
    height: 45vw;
}

.slide1{
    background: url(../../../img/slide1.png) no-repeat right 80% / 70%;
}

.slide2{
    background: url(../../../img/slide2.png) no-repeat 90% top / 65%;
    height: 55vw;
}

.slide3{
    background: url(../../../img/slide3.png) no-repeat right bottom / 60%;
}

button{
    background-color: #5DA9A6;
    color: white;
    border: none;
    border-radius: 4px;
    width: 8vw;
    height: 2.4vw;
    font-size: 0.7vw;
    font-family: ubuntu;
    margin-left: -0.5%;
    position: absolute;
    z-index: 5;
}

.slideText{
    width: 60%;
    padding: 4% 0 0 10%;
}

.slideText h1{
    font-size: 2vw;
    font-family: Ubuntu;
    color: #505D68;
    width: 70%;
    margin-top: 5%;
}

.slideText p{
    font-size: 1vw;
    color: #A1AEB7;
    width: 58%;
}

.slide2 h1{
    font-size: 4vw;
    width: 100%;
}

.slide1 p{
    margin: 2% 0% 6% 0%;
}

.slide2 p{
    margin: 6% 0% 2% 0%;
}

.slide3 p{
    margin: 6% 0% 6% 0%;
}

.rightAnc a{
    position: absolute;
    right: 8vw;
    bottom: 14vw;
    z-index: 5;
}

.slide2 img{
    width: 10vw;
    height: 2.7vw;
}

.slide2 a:nth-child(2n){
    bottom: 19vw;
}

.arrows{
    background-color: transparent;
    padding-bottom: 60%;
    width: 3vw;
}