#iconBackground{
    background: url(../../img/loading.png) no-repeat top center / 50vw auto;
	position: absolute;
	width: 100vw;
    height: 25vw;
}

#opacity{
    width: 100vw;
    height: 100%;
    background-color: rgba(240, 248, 255, 0.5);
    z-index: 999;
    position: absolute;
	bottom: 0px;
	animation: 10s infinite linear loadingAnimation;
}

@keyframes loadingAnimation {
	0% {
		bottom: 10%;
	}
	
	25% {
		bottom: 30%;
	}
	
	70% {
		bottom: 50%;
	}
	
	90% {
		bottom: 60%;
	}
}