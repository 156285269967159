.arquitecturaContainer{
    background-color: #96B5DD;
    padding: 0 0 10vw 10vw;
    margin-top: -10vw;
}

.arquitecturaContainer img{
    background-color: white;
    padding: 5vw;
    width: 50vw;
}

.arquitecturaPointersContainer{
    display: grid;
    gap: 2vw;
}

.arquitecturaContainer h1{
    font-size: 3vw;
    color: white;
    width: 30vw;
    padding-top: 20vw;
}

.arquitecturaContainer p{
    color: white;
    width: 30vw;
    padding: 2vw 0;
    font-size: 1vw;
}