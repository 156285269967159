.upButton{
    width: 3vw;
    height: 3vw;
    border-radius: 100%;
    position: fixed;
    bottom: 5vw;
    right: 5vw;
    z-index: 5;
    background-color: #334F82;
    padding: 0;
    padding-bottom: 0.4vw;
}

.upButton img{
    height: 1vw;
    width: 1.7vw;
}