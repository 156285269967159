header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.12);
    z-index: 10;
    background-color: white;
}

header img{
    cursor: pointer;
}

.top{
    height: 9vw;
    transition-duration: 1s;
    transform: scaleY(1);
}


.small{
    transition-duration: 1s;
    height: 5vw;
    transform: scaleY(1);
}

.top img{
    transition-duration: 1s;
    transform: scale(1, 1);
    margin-left: 4vw;
    height: 7vw;
}

.small img{
    transition-duration: 1s;
    transform: scale(0.7, 0.7);
    margin-left: 2.3vw;
    height: 5vw;
}

.links{
    display: flex;
    width:  40%;
    justify-content: space-evenly;
}

.NavLink{
    font-family: Ubuntu;
    text-decoration: none;
    color: #505D68;
    font-size: 0.8vw;
    cursor: pointer;
}

.blockNavBar{
    height: 5vw;
}

.blockNavBar img{
    margin-left: 4vw;
    height: 3vw;
}