.nosotras{
    padding-top: 15vw;
    background-color: white;
}

.nosotras span{
    display: flex;
    justify-content: center;
}

.nosotras h1{
    text-align: center;
    color: #126CA7;
    font-size: 3vw;
    margin-bottom: 1vw;
}

.nosotras p{
    text-align: center;
}

.acerca p{
    width: 35vw;
    color: black;
    margin: 0;
    font-size: 1vw;
}

.acerca{
    display: grid;
    justify-items: center;
}

.acerca a{
    color: black;
    text-decoration: none;
    font-size: 1vw;
}

#nosotras{
    position: relative;
    bottom: 5vw;
}

.nosotrasBlockContainer{
    display: flex;
    justify-content: space-between;
    padding: 0px 18vw 0 18vw;
}

.nosotrasBlock{
    width: 20vw;
    color: black;
    margin: 0;
    font-size: 1vw;
}

.nosotrasBlock h2{
    font-size: 1.8vw;
    position: relative;
}

.nosotrasBlock p{
    text-align: start;
    font-size: 1vw;
    color: #A1AEB7;
}

.nosotrasBlock h1{
    position: relative;
    font-size: 16vw;
    color: #5C96C6;
    right: 5.5vw;
    top: 6vw;
    opacity: 0.3;
}