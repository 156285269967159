.carousel{
    height: 45vw;
}

.carousel-indicators{
    justify-content: flex-start !important;
    margin-left: 10% !important;
}

.carousel-indicators button{
    width: 0.3vw !important;
    height: 0.3vw !important;
    border-radius: 100%;
    background-color: transparent !important;
    border: 0.2vw solid #A1AEB7 !important;
    margin-right: 1% !important;
    position: relative;
}

.carousel-indicators .active{
    background-color: #5DA9A6 !important;
    border: 0.15vw solid #5DA9A6 !important;
    margin: 0.05vw;
}
